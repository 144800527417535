.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.logo h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}

h1 {
  font-size: 20px;
  color: cornflowerblue;
  text-align: center;
}
p {
  font-size: 15px;
  text-align: justify;
  text-justify: auto;
}

img {
  float: inherit;
  vertical-align: middle;
}
div#container {
  height: 400px;
  width: 400px;
  display: inline-block;
  vertical-align: auto;
}
div#contenidoInfo {
  height: 100px;
  width: 300px;
  display: inline-block;
  vertical-align: auto;
  margin-top: 2em;
}
.imgInfo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.imgInfo1 {
  width: 200px;
  height: 100px;
  margin: 20px auto;
  display: block;
  float: right;
  margin-top: 2em;
  margin-left: 2em;
}
.imgInfo2 {
  width: 200px;
  height: 100px;
  margin: 20px auto;
  display: block;
  float: left;
  margin-top: 2em;
  margin-right: 2em;
}

.leaflet-container {
  height: 100%;
}

.ant-form-inline .ant-form-item {
  margin-bottom: 16px;
}